<template>
  <div id="main-wrapper" class="inner-pages why-page">
    <div class="section banner-holder">
      <div class="title-holder t-white t-center center-div wow fadeIn">
        <h2>Why AAAFx?</h2>
        <p>Par Excellence with Honesty and Transparency</p>
      </div>
    </div>
    <div class="section why-bel">
      <div class="wrapper">
        <div class="content-sec wow fadeIn">
          <div class="title-holder t-center">
            <h3>Why Believe in AAAFx?</h3>
          </div>
          <p>
            We have more than a decade long history of providing our global
            clients with reliable and excellent service. We establish full
            transparency with our clients, so that they can make well informed
            decisions. <br /><br />
            We know what it takes to be a successful trader, and hence aim to
            provide top notch trading conditions and customer support to our
            clients. We are proud to be one of the most trusted brokers in forex
            market, serving retail as well as institutional clients.
          </p>
          <br />
          <ul class="listing">
            <li>Secure and Reliable Broker</li>
            <li>Lightning-fast execution</li>
            <li>Zero Commission, Raw Spreads on Forex</li>
            <li>Deep liquidity Pool</li>
            <li>3 Premium Trading Platforms</li>
            <li>Equinix data centres equipped with high-end technology</li>
            <li>14 years’ experience</li>
            <li>Several methods to make Cost-free Deposits & Withdrawals</li>
          </ul>
          <br />
          <h5>What’s more?</h5>
          <ul class="half-width">
            <li>Auto Connect to the largest social trading community</li>
            <li>And the entire AAAFx team instantly at your service!!</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section why-bel pledge-sec">
      <div class="wrapper clearfix">
        <div class="half-width f-right wow fadeInRight">
          <div class="title-holder t-left">
            <h3>We Pledge...</h3>
          </div>
          <ul class="full-width listing">
            <li>
              To supply you with a brokerage service working in the best
              interests of traders.
            </li>
            <li>
              To abide by the Rules published under the relevant regulatory
              frameworks.
            </li>
            <li>
              To continuously look for opportunities to innovate and improvise.
            </li>
            <li>To adhere to our Core Values at all times.</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section founded-sec">
      <div class="wrapper2">
        <div class="founded-holder wow fadeIn">
          <h4>FOUNDED IN</h4>
          <h3>2008</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="0.3s">
          <h4>COUNTRIES WE SERVE</h4>
          <h3>30</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="0.6s">
          <h4>Instruments to Trade</h4>
          <h3>200+</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="0.9s">
          <h4>Platforms we offer</h4>
          <h3>3</h3>
        </div>
        <div class="founded-holder wow fadeIn" data-wow-delay="1.2s">
          <h4>lIVE Support</h4>
          <h3>24/5</h3>
        </div>
      </div>
    </div>
    <div class="section our-team">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>Our Team...</h3>
        </div>
        <div class="content-holder t-center wow fadeIn">
          <p class="italic">
            Individually, our ideals may appear self-evident. But when we put
            them all together, we have our own corporate culture. Who we are,
            what we stand for, and how we act is all shaped by our values.
          </p>
          <br />
          <p>
            Our Management is a team of strong-minded able professionals who
            have vast experience working on the Wall Street. Hence, they know
            exactly what it takes to be a successful trader. With their formidable background and business experience, they possess insightful knowledge of all of the industry’s best and worst practices.
          </p>
        </div>
        <div class="team-listing">
          <div class="team-holder wow fadeIn">
            <div class="img-holder">
              <img
                src="assets/images/team1.webp"
                alt="AAAFx"
                width="242"
                height="242"
                class="fluid-img"
              />
            </div>
            <p>
              Strong-willed individuals with extensive wall-street experience
            </p>
          </div>
          <div class="team-holder wow fadeIn" data-wow-delay="0.3s">
            <div class="img-holder">
              <img
                src="assets/images/team2.webp"
                alt="AAAFx"
                title="AAAFx"
                width="242"
                height="242"
                class="fluid-img"
              />
            </div>
            <p>Always striving for innovation and improvement</p>
          </div>
          <div class="team-holder wow fadeIn" data-wow-delay="0.6s">
            <div class="img-holder">
              <img
                src="assets/images/team3.webp"
                alt="AAAFx"
                title="AAAFx"
                width="242"
                height="242"
                class="fluid-img"
              />
            </div>
            <p>
              Client First Approach,<br />
              in everything we do
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="section our-team">
      <div class="wrapper2">
        <div class="title-holder wow fadeIn">
          <h3>Our Values, Your success!!</h3>
        </div>
        <div class="content-holder t-center wow fadeIn">
          <p>
            We are on solid grounds, as we foster a value-based environment in
            all of our offices, which is driven by our management’s strong
            belief in business ethics.
          </p>
          <br />
          <p class="italic">
            We stick to our 5 cores values, so that you can shine in the global
            trading community.
          </p>
        </div>
        <div class="img-holder t-center wow fadeIn" data-wow-delay="0.6s">
          <img src="assets/images/core-value.webp" alt="" />
        </div>
      </div>
    </div>
    <div class="section happy-clients">
      <div class="wrapper">
        <div class="title-holder t-white t-center wow fadeIn">
          <h3>
            Happy clients in more than
            <strong>30 EU countries*</strong>, taking<br />
            advantage of our world-class services.
          </h3>
          <p>*Except Belgium and France</p>
        </div>

        <div class="img-holder">
          <img
            src="assets/images/happy-map.webp"
            alt="AAAFx"
            title="AAAFx"
            width="968"
            height="581"
            class="wow fadeIn fluid-img"
          />
          <img
            src="assets/images/happy-logo.webp"
            alt="AAAFx"
            title="AAAFx"
            width="542"
            height="105"
            class="center-div wow fadeIn fluid-img"
            data-wow-delay="0.8s"
          />
        </div>
      </div>
    </div>
    <!-- <ul>
			<li><a href="assets/Regulatory_PDF/AAAFxCertifiedEmployees.el.pdf" target="_blank">AAAFx Πιστοποιημένοι Υπάλληλοι</a></li>
		</ul>
		<div id="main_ctl00_pnlFinTitle">
			<p class="sub-header" @click=" (showTab == 1) ? showTab = 0 : showTab = 1">ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ</p>
			<ul v-show="showTab == 1">
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2021.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2021</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2021Α.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2021</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2020.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2020</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2020Α.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2020</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2019.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2019</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2019A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2019</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2018.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2018</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2018A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2018</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2017.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2017</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2017A.pdf?3" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2017</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2016.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2016</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2016A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2016</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2015.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2015</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2015A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2015</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2014.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2014</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2014A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2014</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2013.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2013</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2013A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2013</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2012.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2012</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2012A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2012</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2011.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2011</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2011A.pdf" target="_blank">ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2011</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2010.pdf" target="_blank">ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2010</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2015.pdf" target="_blank">ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp; ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2015</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2014.pdf" target="_blank">ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp; ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2014</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2013.pdf" target="_blank">ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp; ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2013</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2012.pdf?2" target="_blank">ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp; ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2012</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2011.pdf" target="_blank">ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp; ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2011</a></li>
				<li><a href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2010.pdf" target="_blank">ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp; ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2010</a></li>
			</ul>
			<p class="sub-header" @click="(showTab == 2) ? showTab = 0 : showTab = 2">ΕΠΟΠΤΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ</p>
			<ul v-show="showTab == 2">
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2021.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2021_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2020.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2020</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2019.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2019</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2018.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2018</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2017.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2017</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2016.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2016</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2015_c.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2015</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2014.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2013.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2013</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2012.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2012</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2011.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2011</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/financial_document2010.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ 31-12-2010</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2020.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2020_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2019.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2019_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2018.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2018_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2017.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2017_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2016.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2016_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2015.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2015_α81&amp;82_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2014b.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2015_α80 ΕΩΣ 87_Ν4261_2014</a></li>
				<li><a href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2014.pdf" target="_blank">ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2014_α81&amp;82_Ν4261_2014</a></li>
			</ul>
			<p class="sub-header" @click="(showTab == 3) ? showTab = 0 : showTab = 3">ΑΝΑΚΟΙΝΩΣΕΙΣ</p>
			<ul v-show="showTab == 3">
				<li><a href="assets/Regulatory_PDF/Annoucements/top_venues_2020.pdf" target="_blank">2020 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ</a></li>
				<li><a href="assets/Regulatory_PDF/Annoucements/top_venues_2019.pdf" target="_blank">2019 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ</a></li>
				<li><a href="assets/Regulatory_PDF/Annoucements/top_venues_2018.pdf" target="_blank">2018 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ</a></li>
				<li><a href="assets/Regulatory_PDF/Annoucements/top_venues_2017.pdf" target="_blank">2017 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ</a></li>
				<li><a href="assets/Regulatory_PDF/Annoucements/meeting_28062013.pdf" target="_blank">ΠΡΟΣΚΛΗΣΗ ΤΑΚΤΙΚΗΣ ΓΕΝΙΚΗΣ ΣΥΝΕΛΕΥΣΗΣ 28-06-2013</a></li>
			</ul>
		</div> -->
    <div class="section why-bel">
      <div class="wrapper">
        <div class="content-sec wow fadeIn">
          <div class="containerFluid">
            <h5>
              <a
                href="assets/Regulatory_PDF/AAAFxCertifiedEmployees.el.pdf"
                target="_blank"
                >ΕΝΗΜΕΡΩΤΙΚΑ ΣΤΟΙΧΕΙΑ ΕΤΑΙΡΕΙΑΣ/COMPANY INFORMATION</a
              >
            </h5>
            <button
              class="accordion"
              @click="showTab == 1 ? (showTab = 0) : (showTab = 1)"
              :class="[{ 'is-open': showTab == 1 }]"
            >
            ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ/FINANCIAL DATA
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content" v-show="showTab == 1">
              <ul v-show="showTab == 1" class="regulatory">
                
                <li>
                  <a href="assets/Regulatory_PDF/financial_data/ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2024.pdf" target="_blank" >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2024</a>
                </li>
                <li>
                  <a href="assets/Regulatory_PDF/financial_data/ΟΙΚΟΝΟΜΙΚΕΣ_ΚΑΤΑΣΤΑΣΕΙΣ_31-12-2023.pdf" target="_blank" >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2023</a>
                </li>
                <li>
                  <!-- <a
                    href="assets/Regulatory_PDF/financial_data/INTERIM_SUMMARY_FINANCIAL_INFORMATION_06-30-2022.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2022</a
                  > -->
                  <a href="assets/Regulatory_PDF/financial_data/ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2023.pdf" target="_blank" >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2023</a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/FINANCIAL STATEMENTS 12-31-2022.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2022</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2022.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2022</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2021.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2021</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2021A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2021</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2020.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2020</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2020Α.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2020</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2019.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2019</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2019A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2019</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2018.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2018</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2018A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2018</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2017.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2017</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2017A.pdf?3"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2017</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2016.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2016</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2016A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2016</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2015.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2015</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2015A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2015</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2014.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2014A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2013.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2013</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2013A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2013</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2012.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2012</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2012A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2012</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2011.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2011</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2011A.pdf"
                    target="_blank"
                    >ΕΝΔΙΑΜΕΣΗ ΣΥΝΟΠΤΙΚΗ ΟΙΚΟΝΟΜΙΚΗ ΠΛΗΡΟΦΟΡΗΣΗ 30-06-2011</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/AAAFxFinancialStatement2010.pdf"
                    target="_blank"
                    >ΟΙΚΟΝΟΜΙΚΕΣ ΚΑΤΑΣΤΑΣΕΙΣ 31-12-2010</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2015.pdf"
                    target="_blank"
                    >ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp;
                    ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2015</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2014.pdf"
                    target="_blank"
                    >ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp;
                    ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2013.pdf"
                    target="_blank"
                    >ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp;
                    ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2013</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2012.pdf?2"
                    target="_blank"
                    >ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp;
                    ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2012</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2011.pdf"
                    target="_blank"
                    >ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp;
                    ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2011</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/financial_data/ISOLOGISMOS_2010.pdf"
                    target="_blank"
                    >ΙΣΟΛΟΓΙΣΜΟΣ ( ΣΥΝΟΠΤΙΚΑ ΟΙΚΟΝΟΜΙΚΑ ΣΤΟΙΧΕΙΑ &amp;
                    ΠΛΗΡΟΦΟΡΙΕΣ ) 31-12-2010</a
                  >
                </li>
              </ul>
            </div>
            <button
              class="accordion"
              @click="showTab == 2 ? (showTab = 0) : (showTab = 2)"
              :class="[{ 'is-open': showTab == 2 }]"
            >
            ΕΠΟΠΤΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ/SUPERVISORY INFORMATION
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <ul v-show="showTab == 2" class="regulatory">
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/PillarIIIDisclosuresTripleA2023.pdf"
                    target="_blank"
                    >
                    2023 Pillar III Market Discipline and Disclosure Report
                  </a>
                </li>
               
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/PillarIIIDisclosuresTripleA2022.pdf"
                    target="_blank"
                    >
                    2022 Pillar III Market Discipline and Disclosure Report
                  </a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/PillarIIIDisclosuresTripleA2021.pdf"
                    target="_blank"
                    >
                    2021 Pillar III Market Discipline and Disclosure Report
                  </a>
                </li>





               
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2023_α80&87_Ν4261_2014.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2023_α80&87_Ν4261_2014
                  </a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/CORPORATE GOVERNANCE AND REMUNERATION.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2022_α80&87_Ν4261_2014
                  </a>
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2021.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2021_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2020.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ
                    31-12-2020</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2019.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ
                    31-12-2019</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2018.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ
                    31-12-2018</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2017.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ
                    31-12-2017</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2016.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ
                    31-12-2016</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2015_c.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ ΜΕ ΗΜΕΡΟΜΗΝΙΑ ΑΝΑΦΟΡΑΣ
                    31-12-2015</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2014.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ
                    ΑΝΑΦΟΡΑΣ 31-12-2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2013.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ
                    ΑΝΑΦΟΡΑΣ 31-12-2013</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2012.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ
                    ΑΝΑΦΟΡΑΣ 31-12-2012</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2011.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ
                    ΑΝΑΦΟΡΑΣ 31-12-2011</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/financial_document2010.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΑΠΟΦΑΣΗΣ ΕΚ 9 459-27/12/2007 ΗΜΕΡΟΜΗΝΙΑ
                    ΑΝΑΦΟΡΑΣ 31-12-2010</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2020.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2020_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2019.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2019_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2018.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2018_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2017.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2017_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2016.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2016_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2015.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ
                    2015_α81&amp;82_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2014b.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ 2015_α80 ΕΩΣ
                    87_Ν4261_2014</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Supervisory_Information/FinancialDetails2014.pdf"
                    target="_blank"
                    >ΔΗΜΟΣΙΟΠΟΙΗΣΗ ΕΠΟΠΤΙΚΩΝ ΠΛΗΡΟΦΟΡΙΩΝ
                    2014_α81&amp;82_Ν4261_2014</a
                  >
                </li>
              </ul>
            </div>
            <button
              class="accordion"
              @click="showTab == 3 ? (showTab = 0) : (showTab = 3)"
              :class="[{ 'is-open': showTab == 3 }]"
            >
            ΑΝΑΚΟΙΝΩΣΕΙΣ/ANNOUNCEMENTS
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <ul v-show="showTab == 3" class="regulatory">
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/AAAFx_Execution_Venues_2023.pdf"
                    target="_blank"
                    >2023 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2023 EXECUTION VENUES</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/AAAFx_Execution_Venues_2022.pdf"
                    target="_blank"
                    >2022 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2022 EXECUTION VENUES</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/AAAFx_Execution_Venues_2021.pdf"
                    target="_blank"
                    >2021 ΤΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2021 EXECUTION VENUES</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2020.pdf"
                    target="_blank"
                    >2020 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2020 EXECUTION VENUE</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2019.pdf"
                    target="_blank"
                    >2019 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2019 EXECUTION VENUE</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2018.pdf"
                    target="_blank"
                    >2018 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2018 EXECUTION VENUE</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/top_venues_2017.pdf"
                    target="_blank"
                    >2017 TΟΠΟΙ ΕΚΤ. ΑΝΑ ΚΑΤΗΓ. ΧΡΗΜ. ΜΕΣΟΥ/2017 EXECUTION VENUE</a
                  >
                </li>
                <li>
                  <a
                    href="assets/Regulatory_PDF/Annoucements/meeting_28062013.pdf"
                    target="_blank"
                    >ΠΡΟΣΚΛΗΣΗ ΤΑΚΤΙΚΗΣ ΓΕΝΙΚΗΣ ΣΥΝΕΛΕΥΣΗΣ 28-06-2013</a
                  >
                </li>
              </ul>
            </div>
            <button
              class="accordion"
              @click="showTab == 4 ? (showTab = 0) : (showTab = 4)"
              :class="[{ 'is-open': showTab == 4 }]"
            >
            ΟΡΓΑΝΟΓΡΑΜΜΑ/COMPANY ORGANISATION CHART
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content" v-show="showTab == 4">
              <div class="d-flex justify-content-center"> 
                <h3>AAAFx - Official Organizational Chart (HCMC)</h3>
              </div>
              <!-- <img src="assets/images/team1.webp" alt="AAAFx" width="242" height="242" class="fluid-img"/> -->
              <img
                src="assets/images/COMPANY_ORGANISATION_CHART.png"
                alt="COMPANY ORGANISATION CHART"
                height="500"
                class="fluid-img"
                style="width:100%; padding:10px"
              />
            </div>
            <button
              class="accordion"
              @click="showTab == 5 ? (showTab = 0) : (showTab = 5)"
              :class="[{ 'is-open': showTab == 5 }]"
            >
            ΠΙΣΤΟΠΟΙΗΜΕΝΟΙ ΥΠΑΛΛΗΛΟΙ/CERTIFIED EMPLOYEES
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <div v-show="showTab == 5" class="regulatory">
                <div class="table-holder wow table-responsive nowrap-td">
                  <table>
                    <tr>
                      <th>NAME</th>
                      <th>CERTIFICATION</th>
                      <th>ISSUER</th>
                      <th>YEAR</th>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <table class="subtable">
                          <tr>
                            <td width="25%" rowspan="2">GRAMMENIDIS GEORGIOS</td>
                            <td width="25%">
                              Provision of Investment advice in securities, units in collective investment undertakings, money market instruments, derivatives and structured financial products (B)
                            </td>
                            <td width="25%">HCMC</td>
                            <td width="25%">2022</td>
                          </tr>
                          <tr>
                            
                            <td width="25%">
                              Securities Trader
                            </td>
                            <td width="25%">ASE</td>
                            <td width="25%">2020</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td width="25%">DIMOPOULOS CHRISTOS</td>
                      <td width="25%">
                        Reception, transmission and execution of orders on behalf of third parties in securities (A1)
                      </td>
                      <td width="25%">HCMC</td>
                      <td width="25%">2021</td>
                    </tr>
                    <tr>
                      <td width="25%">DORDA AIKATERINI</td>
                      <td width="25%">
                        Reception, transmission and execution of orders on behalf of third parties in securities (A1)
                      </td>
                      <td width="25%">HCMC</td>
                      <td width="25%">2022</td>
                    </tr>
                    <tr>
                      <td width="25%">KARLIS KONSTANTINOS</td>
                      <td width="25%">
                        Client’s Portfolio Management (C) 
                      </td>
                      <td width="25%">HCMC</td>
                      <td width="25%">2022</td>
                    </tr>
                    <tr>
                      <td width="25%">KONTOGEORGI ELENI</td>
                      <td width="25%">
                        Reception, transmission and execution of orders on behalf of third parties in securities (A1)
                      </td>
                      <td width="25%">HCMC</td>
                      <td width="25%">2023</td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <table class="subtable">
                          <tr>
                            <td width="25%" rowspan="2">TZIANOUMI GIANNOULA</td>
                            <td width="25%">
                              Provision of Investment advice in securities, units in collective investment undertakings, money market instruments, derivatives and structured financial products (B)
                            </td>
                            <td width="25%">HCMC</td>
                            <td width="25%">2022</td>
                          </tr>
                          <tr>
                            
                            <td width="25%">
                              Securities Trader
                            </td>
                            <td width="25%">ASE</td>
                            <td width="25%">1999</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      
                        <td width="25%">TZIANOUMI GIANNOULA</td>
                        <td width="25%">
                          Provision of Investment advice in securities, units in collective investment undertakings, money market instruments, derivatives and structured financial products (B)
                        </td>
                        <td width="25%">HCMC</td>
                        <td width="25%">2022</td>
                      
                      
                        <!-- <td width="25%">TZIANOUMI GIANNOULA</td>
                        <td width="25%">
                          Provision of Investment advice in securities, units in collective investment undertakings, money market instruments, derivatives and structured financial products (B)
                        </td>
                        <td width="25%">222222222HCMC</td>
                        <td width="25%">2022</td> -->
                      
                    </tr>
                    <tr>
                      <td width="25%">CHRYSAITOU MARIA ARGYRO</td>
                      <td width="25%">
                        Provision of Investment advice in securities, units in collective investment undertakings, money market instruments, derivatives and structured financial products (B)
                      </td>
                      <td width="25%">HCMC</td>
                      <td width="25%">2022</td>
                    </tr>
                    <!-- <tr>
                      <td width="25%">ANASTASIADOU AIKATERINI</td>
                      <td width="25%">
                        Reception, transmission and execution of orders on
                        behalf of third parties in securities (A1)
                      </td>
                      <td width="25%">HCMC</td>
                      <td width="25%">2018</td>
                    </tr>
                    <tr>
                      <td>DORDA AIKATERINI</td>
                      <td>
                        Reception, transmission and execution of orders on
                        behalf of third parties in securities (A1)
                      </td>
                      <td>HCMC</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td>GRAMMENIDIS GIORGOS</td>
                      <td>
                        Reception, transmission and execution of orders on
                        behalf of third parties in securities (A1)
                      </td>
                      <td>HCMC</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td>DIMOPOULOS CHRISTOS</td>
                      <td>
                        Reception, transmission and execution of orders on
                        behalf of third parties in securities (A1)
                      </td>
                      <td>HCMC</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td>KARLIS KONSTANTINOS</td>
                      <td>Client’s Portfolio Management (C)</td>
                      <td>HCMC</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>RIZOS ORESTIS</td>
                      <td>Analysis on financial instruments or issuers. (D)</td>
                      <td>HCMC</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td>TANAMPASIDIS PANAGIOTIS</td>
                      <td>Analysis on financial instruments or issuers. (D)</td>
                      <td>HCMC</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td>TZIANOUMI GIANNOULA</td>
                      <td>
                        Investment advice in securities, units in collective
                        investment undertakings, money market instruments,
                        derivatives and structured financial products (B)
                      </td>
                      <td>HCMC</td>
                      <td>2017</td>
                    </tr>
                    <tr>
                      <td>TZIANOUMI GIANNOULA</td>
                      <td>Securities Trader</td>
                      <td>ASE</td>
                      <td>1999</td>
                    </tr>
                    <tr>
                      <td>VAMVOULIS STRATOS</td>
                      <td>
                        Investment advice in securities, units in collective
                        investment undertakings, money market instruments,
                        derivatives and structured financial products (B)
                      </td>
                      <td>HCMC</td>
                      <td>2018</td>
                    </tr> -->
                  </table>
                </div>
                <div class="table-holder wow table-responsive nowrap-td">
                  <table>
                    <tr>
                      <th>Ονοματεπώνυμο</th>
                      <th>Πιστοποίηση</th>
                      <th>Φορέας</th>
                      <th>Έτος Κτήσης</th>
                    </tr>
                    <tr>
                      <td>Γραμμενίδης Γεώργιος</td>
                      <td>
                        Παροχή Επενδυτικών Συμβουλών (Β)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td>Δημόπουλος Χρήστος</td>
                      <td>
                        Λήψη, Διαβίβαση και Εκτέλεση Εντολών σε Κινητές Αξίες (Α1)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td>Καρλής Κωνσταντίνος</td>
                      <td>
                        Διαχείριση Χαρτοφυλακίων (Γ)   
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>Κοντογεώργη Ελένη</td>
                      <td>
                        Λήψη, Διαβίβαση και Εκτέλεση Εντολών σε Κινητές Αξίες (Α1)   
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2023</td>
                    </tr>
                    <tr>
                      <td>Ντόρντα Αικατερίνη</td>
                      <td>
                        Λήψη, Διαβίβαση και Εκτέλεση Εντολών σε Κινητές Αξίες (Α1) 
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>Τζιανούμη Γιαννούλα</td>
                      <td>
                        Παροχή Επενδυτικών Συμβουλών (Β) 
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        Πιστοποιημένος Διαπραγματευτής Αξιών
                      </td>
                      <td>Χρηματιστήριο Αξιών Αθηνών</td>
                      <td>1999</td>
                    </tr>
                    <tr>
                      <td>Χρυσαΐτου Μαρία Αργυρώ</td>
                      <td>
                        Παροχή Επενδυτικών Συμβουλών (Β)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <!-- <tr>
                      <td>Αναστασιάδου Αικατερίνη</td>
                      <td>
                        Λήψη, ∆ιαβίβαση και εκτέλεση εντολών σε κινητές αξίες
                        (Α1)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>Βαμβουλής Ευστράτιος</td>
                      <td>Παροχή επενδυτικών συμβουλών (Β)</td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td>Γραμμενίδης Γεώργιος</td>
                      <td>Παροχή επενδυτικών συμβουλών (Β)</td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2020</td>
                    </tr>
                    <tr>
                      <td>Δημόπουλος Χρήστος</td>
                      <td>
                        Λήψη, ∆ιαβίβαση και εκτέλεση εντολών σε κινητές αξίες
                        (Α1)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2021</td>
                    </tr>
                    <tr>
                      <td>Καρλής Κωνσταντίνος</td>
                      <td>Διαχείρηση Χαροφυλακίων(Γ)</td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>Ντόρντα Αικατερίνη</td>
                      <td>
                        Λήψη, ∆ιαβίβαση και εκτέλεση εντολών σε κινητές αξίες
                        (Α1)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td>Ρίζος Ορέστης</td>
                      <td>
                        Εκπόνηση αναλύσεων σχετικά με χρηματοπιστωτικά μέσα ή
                        εκδότες (Δ)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2018</td>
                    </tr>
                    <tr>
                      <td>Ταναμπασίδης Παναγιώτης</td>
                      <td>
                        Εκπόνηση αναλύσεων σχετικά με χρηματοπιστωτικά μέσα ή
                        εκδότες (Δ)
                      </td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2019</td>
                    </tr>
                    <tr>
                      <td>Τζιανούμη Γιαννούλα</td>
                      <td>Παροχή επενδυτικών συμβουλών (Β)</td>
                      <td>Επιτροπή Κεφαλαιαγοράς</td>
                      <td>2022</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>Πιστοποιημένος Διαπραγματευτής Αξιών</td>
                      <td>Χρηματιστήριο Αξιών Αθηνών</td>
                      <td>1999</td>
                    </tr> -->
                  </table>
                </div>
              </div>
            </div>
            <button
              class="accordion"
              @click="showTab == 6 ? (showTab = 0) : (showTab = 6)"
              :class="[{ 'is-open': showTab == 6 }]"
            >
            ΓΝΩΣΤΟΠΟΙΗΣΕΙΣ ΚΑΝΟΝΙΣΜΟΥ ΑΕΙΦΟΡΙΑΣ (SFDR)/SUSTAINABILITY REGULATION DISCLOSURES (SFDR)
              <vue-feather type="chevron-down" size="20px"></vue-feather>
            </button>
            <div class="accordion-content">
              <div v-show="showTab == 6" class="regulatory">
                <ul class="regulatory">
                  <li>
                    <a href="assets/Regulatory_PDF/Sfdr/Sustainability-Regulation-Disclosures-(SFDR).pdf" target="_blank" >Sustainability Regulation Disclosures (SFDR)</a>
                  </li>
                  <li>
                    <a href="assets/Regulatory_PDF/Sfdr/Γνωστοποιήσεις-Κανονισμού-Αειφορίας-(SFDR)-και-Πολιτική-Βιωσιμότητας-(ESG).pdf" target="_blank" >Γνωστοποιήσεις Κανονισμού Αειφορίας (SFDR) και Πολιτική Βιωσιμότητας (ESG)</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTab: 0,
    };
  },
};
</script>
